import { Button, Col, Row } from 'react-bootstrap';
import { useLocation } from 'react-router-dom';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { $global } from 'signals/Global.signals';
import img from 'images/404Background.svg';
import NavBar from 'components/global/NavBar';
import ThemeImage from 'components/global/ThemeImage/ThemeImage';
import { useEffect } from 'react';
import { ACCT_PROVIDER } from '@accru/client';
import notifyDisconnection from 'components/views/Disconnect/Disconnect.helpers';
// import QuickBooksButton from 'components/global/QuickBooksButton';
// import { $accountingOAuth, getAccountingOAuthUrl, handleAccountingOAuthCallback } from 'components/views/SettingsAccounting/SettingsAccounting.helpers';
// import useOAuthCallback from 'hooks/useOAuthCallback';
// import { handleNotification } from 'components/global/Alert/Alert';

const Disconnect = () => {
  const history = useHistory();
  const location = useLocation();

  const queryParams = new URLSearchParams(location.search);
  const realmId = queryParams.get('realmId');

  // const isConnecting = !!$accountingOAuth.value.provider;
  const { isSignedIn, isLoading } = $global.value;

  useEffect(() => {
    if (realmId) {
      notifyDisconnection({ acctProvider: ACCT_PROVIDER.QUICKBOOKS, acctProviderConnCode: realmId });
    }
  }, [realmId]);
  // const { dispatchOAuth } = useOAuthCallback({
  //   signal: $accountingOAuth,
  //   onSuccess: handleAccountingOAuthCallback,
  //   onFailure: () => handleNotification('Something went wrong while connecting your accounting software. Please try again.', { variant: 'danger' }),
  // });

  // const handleConnect = async (provider) => {
  //   const url = await getAccountingOAuthUrl(provider);
  //   dispatchOAuth(url, provider);
  // };

  return (
    <div>
      <div
        className="d-flex p-0 justify-content-between flex-column 100vh bg-404"
        style={{ backgroundImage: `url(${img})` }}
      >
        {!isLoading && isSignedIn && (
          <div className="pb-40">
            <NavBar />
          </div>
        )}
        <div className="container d-flex h-100 justify-content-center align-items-center">
          <div className="d-flex flex-column align-items-center justify-content-around">
            <h2 className="text-dark fw-700 text-center">Looks like QuickBooks Disconnected</h2>
            <ThemeImage name="disconnect.svg" className="img-fluid" alt="disconnect" />

            <h4 className="text-dark fw-700 text-center mt-16 w-50">Follow these steps to restart your data sync between <span className="text-primary fw-500">Accru</span> and QuickBooks</h4>
            <Row className="d-flex justify-content-center  w-50">
              <Col>
                <p className="text-dark-300 fw-300 text-start">
                  <strong>1. </strong>Login to <span className="text-primary fw-500">Accru</span> and click on your user profile icon in the top-right corner of the navigation bar, then select `Settings` from the dropdown menu.
                  <br />
                  <strong>2. </strong>Click on the `Accounting Software` card.
                  <br />
                  <strong>3. </strong>Click on the `Connect to QuickBooks` button.
                </p>
              </Col>
            </Row>
            {!isLoading && !isSignedIn && (
              <Button onClick={() => history.push('/login')} className="mt-16" variant="primary">Login to Reconnect</Button>
            )}
            {!isLoading && isSignedIn && (
            <Button onClick={() => history.push('/customers')} className="pt-0 text-dark mt-16" variant="link">Back to Customers</Button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Disconnect;

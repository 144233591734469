import { ACCT_PROVIDER } from '@accru/client';
import { accruClient } from 'api';

async function notifyDisconnection({ acctProvider = ACCT_PROVIDER.QUICKBOOKS, acctProviderConnCode }) {
  if (acctProviderConnCode) {
    await accruClient.accountingProviders.verifyConnectionUnauthorized({
      acctProvider,
      acctProviderConnCode,
    }).catch(() => null);
  }
}

export default notifyDisconnection;

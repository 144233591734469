import { Card, Col, Row } from 'react-bootstrap';
import { ORGANIZATION_COUPON_SUBDIVISION } from '@accru/client';
import { handleRemoveCoupon } from 'components/views/SettingsSubscription/SettingsSettingsSubscription.helpers';

const discountTextMap = {
  [ORGANIZATION_COUPON_SUBDIVISION.ORGANIZATION_SUBSCRIPTION_FOUNDING_PRICING_TIER]: "You're getting the Founding Member pricing! Thanks for being an early supporter.",
};

const CouponCard = ({ coupon }) => {
  if (!coupon) return <></>;
  const { subdivision, category_description, subdivision_description } = coupon;

  return (
    <Card className="bg-info border-primary-50 border p-24 mb-16">
      <Row className="mx-0 mb-16">
        <Col xs={8} className="p-0 text-start">
          <h3 className="mb-0 fw-400">
            <span className="fw-bold">Discount Applied!</span>
          </h3>
        </Col>
        <Col className="p-0 text-end">
          <button type="button" onClick={handleRemoveCoupon} className="btn btn-link p-0 m-0">
            Remove Coupon
          </button>
        </Col>
      </Row>
      <Row>
        <Col>
          <h5 className="fw-300">{discountTextMap[subdivision] || `${category_description} - ${subdivision_description}`}</h5>
        </Col>
      </Row>
    </Card>
  );
};

export default CouponCard;

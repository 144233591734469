import { Button, Col, Container, Row } from 'react-bootstrap';
import {
  $onboarding,
  acceptOnboardingInvite,
  skipOnboardingUserInvites,
} from 'components/views/Onboarding/Onboarding.helpers';

const UserInvitesOnboarding = () => {
  const { userInvites } = $onboarding.value;

  return (
    <div>
      <h2 className="fw-700 text-center mb-40">You have been invited to join the following organizations:</h2>
      <Container className="d-flex align-items-center justify-content-center mb-48">
        {userInvites?.map((invite, index) => (
          <Row key={index} className="mb-3">
            <Col xs={2}>
              <div className="rounded-circle border border-primary position-relative d-flex align-items-center justify-content-center overflow-hidden" style={{ height: '45px', width: '45px' }}>
                <h2 className="fw-700 mb-0 text-primary p-0">{invite.organization.name?.charAt(0)?.toUpperCase()}</h2>
              </div>
            </Col>
            <Col>
              <h3 className="m-0">{invite.organization.name}</h3>
              {!!invite.created_by_user.first_name && (<small>Invited by {invite.created_by_user.first_name}</small>)}
            </Col>
            <Col xs={2}>
              <Button
                variant="success"
                disabled={$onboarding.value.isLoading}
                onClick={() => acceptOnboardingInvite(invite.id)}
              >
                Accept
              </Button>
            </Col>
          </Row>
        ))}
      </Container>
      <Row>
        <Col>
          <Button
            variant="outline-primary"
            type="submit"
            onClick={() => skipOnboardingUserInvites()}
            disabled={$onboarding.value.isLoading}
            className="w-100 btn-lg mb-48"
          >
            SKIP
          </Button>
        </Col>
      </Row>
    </div>
  );
};

export default UserInvitesOnboarding;

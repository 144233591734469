import { Button, Col, Form, Image, OverlayTrigger, Row, Tooltip } from 'react-bootstrap';
import SelectInput from 'components/global/Inputs/SelectInput';
import UniversalInput from 'components/global/Inputs/UniversalInput';
import {
  addMember,
  deleteMember,
  handleMemberChange,
  sendOrganizationOnboardingInvites,
  $invitations,
  skipOrganizationOnboardingInvites,
  handleCancelOrganizationOnboardingInvite,
  fetchAndSetOnboardingOrganizationInvites,
  inviteLimitMessage,
} from 'components/views/Onboarding/Onboarding.helpers';
import { createRoleArray } from 'utils/selectArrays';
import addButtonImg from 'images/addButton.svg';
import deleteIcon from 'images/deleteIcon.svg';
import { useEffect } from 'react';
import $user from 'signals/User.signals';

const InviteMembersForm = () => {
  const { members, existingInvites, existingUsers } = $invitations.value;
  const { currentOrganization } = $user.value;

  useEffect(() => {
    if ($user.value.currentOrganization?.id) fetchAndSetOnboardingOrganizationInvites($user.value.currentOrganization.id);
  }, [currentOrganization?.id]);

  const requestedSeats = !!currentOrganization?.data?.subscription_data?.organization_user_seats && !!members && !!existingInvites && !!existingUsers ? members.length + existingInvites.length + existingUsers.length : Infinity;
  const canSend = requestedSeats <= currentOrganization?.data?.subscription_data?.organization_user_seats;
  const canAddMore = requestedSeats < currentOrganization?.data?.subscription_data?.organization_user_seats;

  return (
    <Form.Group>
      <h2 className="fw-700 text-center mb-24">Let&apos;s invite your team members</h2>
      <>
        <div style={{ maxHeight: '300px', overflowY: 'auto', overflowX: 'hidden' }}>
          {(existingInvites || []).map((invite, index) => (
            <Row key={index} className="m-0">
              <Col xs={6} className="p-0">
                <Form.Label htmlFor="TeamMember">
                  <strong>Team member email</strong>
                </Form.Label>
                <UniversalInput
                  name="memberEmail"
                  customValue={invite.email}
                  className="mb-40"
                  signal={$invitations}
                  disabled
                />
              </Col>
              <Col xs={4} className="px-8">
                <Form.Label htmlFor="TeamMember"><strong>Role</strong></Form.Label>
                <UniversalInput
                  name="memberRole"
                  customValue={invite.role}
                  className="mb-40"
                  signal={$invitations}
                  disabled
                />
              </Col>
              <Col xs={2} className="p-0 d-flex justify-content-center align-items-center">
                <Button
                  variant="eye"
                  type="submit"
                  onClick={() => handleCancelOrganizationOnboardingInvite(invite.id)}
                  className="d-flex justify-content-center align-items-center"
                  disabled={$invitations?.value?.isLoading}
                >
                  <Image src={deleteIcon} alt="delete icon" />
                </Button>
              </Col>
            </Row>
          ))}
          {(members || []).map((member, index) => (
            <Row key={index} className="m-0">
              <Col xs={6} className="p-0">
                <Form.Label htmlFor="TeamMember">
                  <strong>Team member email</strong>
                </Form.Label>
                <UniversalInput
                  customValue={member.email}
                  className="mb-40"
                  customOnChange={(e) => handleMemberChange(e, index, true)}
                  signal={$invitations}
                  disabled={$invitations?.value?.isLoading}
                />
              </Col>
              <Col xs={4} className="px-8">
                <Form.Label htmlFor="TeamMember"><strong>Role</strong></Form.Label>
                <SelectInput
                  name="memberRole"
                  value={member.role}
                  options={createRoleArray}
                  className="mb-40"
                  customOnChange={(e) => handleMemberChange(e, index, false)}
                  signal={$invitations}
                  disabled={$invitations?.value?.isLoading}
                />
              </Col>
              <Col xs={2} className="p-0 d-flex justify-content-center align-items-center">
                <Button
                  variant="eye"
                  type="submit"
                  onClick={(e) => deleteMember(e, index)}
                  className="d-flex justify-content-center align-items-center"
                  disabled={$invitations?.value?.isLoading}
                >
                  <Image src={deleteIcon} alt="delete icon" />
                </Button>
              </Col>
            </Row>
          ))}
          <OverlayTrigger
            key="overlay-trigger"
            placement="bottom"
            overlay={(
              <Tooltip id="tooltip-bottom" className="d-block">
                {inviteLimitMessage}
              </Tooltip>
            )}
            trigger={canAddMore ? [] : ['hover', 'focus']}
          >
            <span className="d-inline-block">
              <Button
                variant="white"
                type="submit"
                onClick={() => addMember()}
                disabled={!canAddMore || $invitations?.value?.isLoading}
                className="mb-8 border-0 d-flex align-items-center p-0"
              >
                <Image src={addButtonImg} alt="add button with plus sign" className="me-16" />
                Add More
              </Button>
            </span>
          </OverlayTrigger>
        </div>
        <OverlayTrigger
          key="overlay-trigger"
          placement="bottom"
          overlay={(
            <Tooltip id="tooltip-bottom" className="d-block">
              {inviteLimitMessage}
            </Tooltip>
            )}
          trigger={canSend ? [] : ['hover', 'focus']}
        >
          <span className="d-inline-block w-100">
            <Button
              variant="primary"
              type="submit"
              onClick={() => sendOrganizationOnboardingInvites()}
              className="w-100 btn-lg mt-48 mb-8"
              disabled={!canSend || members.length === 0 || $invitations?.value?.isLoading}
            >
              SEND INVITES
            </Button>
          </span>
        </OverlayTrigger>
        <Button
          variant="transparent"
          className="w-100 btn-lg mb-16 border-0"
          onClick={() => skipOrganizationOnboardingInvites()}
        >
          <span className="text-decoration-underline fw-800 text-primary">
            Skip for now
          </span>
        </Button>
      </>
    </Form.Group>
  );
};

export default InviteMembersForm;

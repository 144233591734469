import { accruClient } from 'api';
import { handleNotification } from 'components/global/Alert/Alert';
import { auth } from 'utils/firebase';
import { validateEmail, containsLetter, containsNumber, validateLength, validateMatchingFields, validateEmailIsNotIncluded } from 'utils/validateInput';
import history from 'utils/history';
import { $loader } from 'signals/Global.signals';
import Signal from 'signals/Signal';
import { handleFirebaseLogin, $acceptInviteDetail } from 'components/views/Auth/_shared/Auth.helpers';
import logEvent, { setAnalyticsUserProperty } from 'utils/logEvent';
import { acceptInvite } from 'components/views/Auth/AcceptInvite/AcceptInvite.helpers';

export const $signUpForm = Signal({
  email: '',
  password: '',
  confirmPassword: '',
  verificationCode: ['', '', '', '', '', ''],
  timeLeft: 180,
  timerActive: true,
});

export const $signUpFunctionality = Signal({
  currentPage: 'emailForm',
});

export const $passwordForm = Signal({
  passwordVisible: false,
  passwordRequirements: {
    reqLength: false,
    number: false,
    uppercase: false,
    symbol: false,
  },
});

export const handleEmailSubmitPartial = async (email) => {
  try {
    $signUpForm.loadingStart();
    $loader.update({
      isLoading: true,
      isLoadingMessage: 'Loading...',
    });
    validateEmail(email);
    const response = await accruClient.auth.startEmailSignup({ data: { email } });
    if (response) {
      $signUpFunctionality.update({
        currentPage: 'verify',
      });
      logEvent({ name: 'signup.email_signup_start' });
      handleNotification(`Verification code has been sent to ${email}`, { variant: 'success' });
    }
  } catch (error) {
    handleNotification(error);
  } finally {
    $signUpForm.loadingEnd();
    $loader.reset();
  }
};

export const handleVerifyFormChange = (event, index, verificationCode) => {
  if (index <= 5) {
    const oldFormData = [...verificationCode];
    const inputValue = event.target.value;

    if (inputValue.length > 1) {
      const tempArray = inputValue.slice(0, 6 - index).split('');
      tempArray.forEach((value, key) => {
        if (index + key < 6) {
          oldFormData[index + key] = value;
          const nextInput = event.target.parentElement.querySelector(
            `input:nth-child(${index + key + 2})`,
          );
          if (nextInput) {
            nextInput.focus();
          }
        }
      });
    } else {
      oldFormData[index] = inputValue;

      if (inputValue === '') {
        event.target.previousElementSibling?.focus();
      } else if (index < 5) {
        event.target.nextElementSibling?.focus();
      }
    }

    const trimmedData = oldFormData.slice(0, 6);

    $signUpForm.update({
      verificationCode: trimmedData,
    });

    const lastFilledIndex = trimmedData.lastIndexOf((value) => value !== '' && value !== undefined);
    if (lastFilledIndex !== -1) {
      const targetInput = event.target.parentElement.querySelector(
        `input:nth-child(${lastFilledIndex + 1})`,
      );
      if (targetInput) {
        targetInput.focus();
      }
    }
  }
};

export const handleCodeVerificationPartial = async (invited) => {
  try {
    $signUpForm.loadingStart();
    $loader.update({
      isLoading: true,
      isLoadingMessage: 'Loading...',
    });

    const { email, verificationCode } = $signUpForm.value;

    if (verificationCode.length !== 6) {
      throw new Error('The verification code is not complete.');
    }
    const response = await accruClient.auth.verifyEmailSignup({
      data: {
        email,
        verification_code: verificationCode.join(''),
      },
    });
    if (response) {
      if (invited) {
        logEvent({ name: 'invite.verification_complete' });
        $acceptInviteDetail.update({
          step: 'createPassword',
        });
      } else {
        logEvent({ name: 'signup.verification_complete' });
        $signUpFunctionality.update({
          currentPage: 'createPassword',
        });
      }
    }
  } catch (error) {
    handleNotification(error);
    $signUpForm.update({ verificationCode: $signUpForm.initialValue.verificationCode });
  } finally {
    $loader.reset();
    $signUpForm.loadingEnd();
  }
};

export const performCompleteSignup = async (isEmbed) => {
  try {
    $signUpForm.loadingStart();

    const isInvitedUser = !!$acceptInviteDetail.value.organization_invite_id;

    const { email, password, confirmPassword, verificationCode } = $signUpForm.value;
    containsLetter(password, 'Password');
    containsNumber(password, 'Password');
    validateLength(password, 'Password', 12, 32);
    validateEmailIsNotIncluded(email, password, 'Password');
    validateMatchingFields(password, confirmPassword);
    const response = await accruClient.auth.setEmailSignupPassword({
      data: {
        email,
        password,
        verification_code: verificationCode.join(''),
      },
    });

    const authResult = await auth.signInWithCustomToken(response);
    await handleFirebaseLogin(authResult.user);

    if (isInvitedUser) {
      await acceptInvite();
    }

    if (isEmbed) {
      $signUpFunctionality.update({
        currentPage: 'earlyAccess',
      });
      logEvent({ name: 'signup.email_signup_early_access' });
      setAnalyticsUserProperty({ isWaitlisted: true });
      return;
    }

    logEvent({ name: 'signup.email_signup_complete' });
    setAnalyticsUserProperty({ isBetaUser: true });
    history.replace('/onboarding');
  } catch (error) {
    handleNotification(error);
  } finally {
    $signUpForm.loadingEnd();
  }
};

export const validatePassword = (password) => {
  const { passwordRequirements } = $passwordForm.value;
  const { ...requirements } = passwordRequirements;
  requirements.reqLength = password.length >= 12 && password.length <= 36;
  requirements.uppercase = /[A-Z]/.test(password);
  requirements.number = /\d/.test(password);
  requirements.symbol = /[^A-Za-z0-9]/.test(password);
  $passwordForm.update({
    passwordRequirements: requirements,
  });
};

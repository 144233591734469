import { Col, Container, Row, Image } from 'react-bootstrap';
import { $global, $syncing } from 'signals/Global.signals';
import $user from 'signals/User.signals';
import SimpleModal from 'components/global/SimpleModal/SimpleModal';
import quickbooksLogo from 'images/quickbooks-logo.png';
import smileIcon from 'images/smileIcon.svg';
import { useHistory } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faRotate } from '@fortawesome/free-solid-svg-icons';
import { ORGANIZATION_USER_ROLE } from '@accru/client';
import logEvent from 'utils/logEvent';

const PromptSyncModal = () => {
  const history = useHistory();
  return (
    <SimpleModal
      title="Connect your accounting software!"
      isShow={$user.value.currentOrganization?.role === ORGANIZATION_USER_ROLE.OWNER && $user.value.isSyncModalOpen && !$syncing.value.isSyncing && !$global.value.isLoading}
      onClose={() => {
        logEvent({ name: 'sync_modal_closed' });
        $user.update({ isSyncModalOpen: false });
      }}
      buttonPlacement="center"
      saveText="Sync Now!"
      onSave={() => {
        $user.update({ isSyncModalOpen: false });
        logEvent({ name: 'sync_now_clicked' });
        history.push('/sync');
      }}
      cancelText="Later"
      onCancel={() => {
        logEvent({ name: 'sync_modal_closed' });
        $user.update({ isSyncModalOpen: false });
      }}
    >
      <Container fluid>
        <Row className="my-8">
          <Col>
            <div className="d-flex justify-content-center align-items-center">
              <div className="mx-24">
                <Image width={76} src={smileIcon} />
              </div>
              <FontAwesomeIcon icon={faRotate} size="xl" color="#41696c" />
              <div className="mx-24">
                <Image width={200} src={quickbooksLogo} />
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </SimpleModal>
  );
};

export default PromptSyncModal;

/* eslint-disable jsx-a11y/no-static-element-interactions */
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRightLong, faCheck, faChevronRight, faChevronDown, faXmark, faWarning } from '@fortawesome/free-solid-svg-icons';
import { Button, ProgressBar } from 'react-bootstrap';
import Toast from 'react-bootstrap/Toast';
import { $syncing } from 'signals/Global.signals';
import { historyPush } from 'utils/history';

const SyncToast = ({ expanded, collapseToast, dismissToast, id }) => {
  const { isFinished, hasErrors, message, progress } = $syncing.value;

  const icon = hasErrors ? faWarning : faCheck;
  const colorClass = hasErrors ? 'text-warning' : 'text-primary';

  return (
    <>
      <Toast.Header closeButton={false} className="bg-light border-0">
        <div className="cursor-pointer d-flex align-items-center w-100 " onClick={() => collapseToast(id)}>
          <FontAwesomeIcon icon={expanded ? faChevronDown : faChevronRight} role="button" />
          {!expanded && (
            <>
              {isFinished ? (
                <div className="px-8 text-dark">{hasErrors ? 'Sync finished with issues' : 'Sync completed'}</div>
              ) : (
                <div className="px-8 text-dark">Syncing...</div>
              )}
              {isFinished ? (
                <FontAwesomeIcon icon={icon} height={18} className={colorClass} />
              ) : (
                <span className={`rotating ${colorClass}`} style={{ width: '18px', height: '18px' }}>
                  <svg width="18" height="18" viewBox="0 0 24 27" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M17.3005 7.5H23.1992C23.5172 7.5 23.6909 7.87082 23.4873 8.11507L20.538 11.6543C20.388 11.8342 20.1117 11.8342 19.9618 11.6543L17.0124 8.11507C16.8089 7.87082 16.9826 7.5 17.3005 7.5Z" fill="#41696C" />
                    <path d="M0.800522 10.5H6.69924C7.01718 10.5 7.19086 10.1292 6.98732 9.88493L4.03796 6.3457C3.88804 6.16579 3.61172 6.16579 3.4618 6.3457L0.512438 9.88493C0.308899 10.1292 0.482583 10.5 0.800522 10.5Z" fill="#41696C" />
                    <path fillRule="evenodd" clipRule="evenodd" d="M11.9999 1.5C9.67114 1.5 7.59073 2.56045 6.21391 4.22758C5.95015 4.54696 5.47742 4.59205 5.15804 4.32829C4.83866 4.06452 4.79358 3.59179 5.05734 3.27242C6.70689 1.27504 9.20481 0 11.9999 0C16.4124 0 20.0819 3.17476 20.8515 7.3645C20.8598 7.40956 20.8677 7.45472 20.8753 7.5H19.35C18.6555 4.07667 15.6278 1.5 11.9999 1.5ZM4.64976 10.5C5.34428 13.9233 8.37194 16.5 11.9999 16.5C14.3286 16.5 16.409 15.4396 17.7859 13.7724C18.0496 13.453 18.5223 13.408 18.8417 13.6717C19.1611 13.9355 19.2062 14.4082 18.9424 14.7276C17.2929 16.725 14.795 18 11.9999 18C7.58733 18 3.91785 14.8252 3.14826 10.6355C3.13998 10.5904 3.13204 10.5453 3.12444 10.5H4.64976Z" fill="#41696C" />
                  </svg>
                </span>
              )}
            </>
          )}
        </div>
        <FontAwesomeIcon
          icon={faXmark}
          role="button"
          className="ms-auto"
          onClick={() => {
            dismissToast(id);
            $syncing.update({ isSyncToastOpen: false });
          }}
        />
      </Toast.Header>
      <div className={!expanded ? 'd-none' : ''}>
        <Toast.Body>
          <ProgressBar animated={!isFinished} variant={hasErrors ? 'warning' : 'primary'} now={progress} style={{ height: '0.25rem' }} />
          <div className={`d-flex my-3 gap-8 justify-content-center align-items-center ${colorClass}`} style={{ marginTop: '0.5rem' }}>
            {isFinished ? (
              <FontAwesomeIcon icon={hasErrors ? faWarning : faCheck} height={18} />
            ) : (
              <span className={`rotating ${colorClass}`} style={{ width: '18px', height: '18px' }}>
                <svg width="18" height="18" viewBox="0 0 24 27" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M17.3005 7.5H23.1992C23.5172 7.5 23.6909 7.87082 23.4873 8.11507L20.538 11.6543C20.388 11.8342 20.1117 11.8342 19.9618 11.6543L17.0124 8.11507C16.8089 7.87082 16.9826 7.5 17.3005 7.5Z" fill="#41696C" />
                  <path d="M0.800522 10.5H6.69924C7.01718 10.5 7.19086 10.1292 6.98732 9.88493L4.03796 6.3457C3.88804 6.16579 3.61172 6.16579 3.4618 6.3457L0.512438 9.88493C0.308899 10.1292 0.482583 10.5 0.800522 10.5Z" fill="#41696C" />
                  <path fillRule="evenodd" clipRule="evenodd" d="M11.9999 1.5C9.67114 1.5 7.59073 2.56045 6.21391 4.22758C5.95015 4.54696 5.47742 4.59205 5.15804 4.32829C4.83866 4.06452 4.79358 3.59179 5.05734 3.27242C6.70689 1.27504 9.20481 0 11.9999 0C16.4124 0 20.0819 3.17476 20.8515 7.3645C20.8598 7.40956 20.8677 7.45472 20.8753 7.5H19.35C18.6555 4.07667 15.6278 1.5 11.9999 1.5ZM4.64976 10.5C5.34428 13.9233 8.37194 16.5 11.9999 16.5C14.3286 16.5 16.409 15.4396 17.7859 13.7724C18.0496 13.453 18.5223 13.408 18.8417 13.6717C19.1611 13.9355 19.2062 14.4082 18.9424 14.7276C17.2929 16.725 14.795 18 11.9999 18C7.58733 18 3.91785 14.8252 3.14826 10.6355C3.13998 10.5904 3.13204 10.5453 3.12444 10.5H4.64976Z" fill="#41696C" />
                </svg>
              </span>
            )}
            <span className="text-dark">{message}</span>
          </div>
          <div className="d-flex row justify-content-end">
            <Button variant="link" className="d-flex align-items-center justify-content-end me-2 text-decoration-none" tabIndex={0} style={{ marginTop: '1.25rem' }} onClick={() => historyPush('/settings/logs')}>
              <span className="text-primary" style={{ marginRight: '0.4rem' }}>View Details</span>
              <FontAwesomeIcon icon={faArrowRightLong} className="text-primary" />
            </Button>
          </div>
        </Toast.Body>
      </div>
    </>
  );
};

export default SyncToast;

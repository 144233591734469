import { accruClient } from 'api';
import { handleNotification } from 'components/global/Alert/Alert';
import { $global } from 'signals/Global.signals';
import $user from 'signals/User.signals';
import Signal from 'signals/Signal';
import { fetchAndSetUserData } from 'components/views/Auth/_shared/Auth.helpers';
import logEvent from 'utils/logEvent';
import { effect } from '@preact/signals-react';
import formatPhoneNumber from 'utils/formatPhoneNumber';
import formatPhoneNumForApi from 'utils/formatPhoneNumForApi';
import { isOnboardingOrganizationRequired, isOnboardingUserRequired, selectOrganization } from 'components/helpers/User.helpers';
import { validateEmail } from 'utils/validateInput';
import history from 'utils/history';

export const ONBOARDING_STEPS = {
  ACCOUNT_TYPE: 'accountType',
  PERSONAL_INFO: 'personalInfo',
  USER_INVITES: 'userInvites',
  COMPANY_INFO: 'companyInfo',
  SEND_INVITES: 'sendInvites',
  COMPLETE: 'complete',
};

export const $onboarding = Signal({
  accountType: null,
  companyIndustry: '',
  companyName: '',
  firstName: '',
  lastName: '',
  numberOfPeople: 'Select',
  phoneNumber: '',
  formattedPhoneNumber: '',
  organizationId: '',
  step: ONBOARDING_STEPS.ACCOUNT_TYPE,
  invitedUser: false,
  timezone: Intl.DateTimeFormat().resolvedOptions().timeZone.toString(),
  /** @type {AccruResponse<AccruClient['auth']['getInvites']>['items']} */
  userInvites: [],

  isLoading: true,
  loadingMessage: 'Loading...',
});

effect(() => logEvent(`onboarding_${$onboarding.value.step}`));

export const $invitations = Signal({
  /** @type {AccruResponse<AccruClient['organizations']['getCollaboratorInvites']>['items']} */
  existingInvites: [],
  /** @type {AccruResponse<AccruClient['organizations']['getCollaborators']>} */
  existingUsers: [],
  members: [
    {
      email: '',
      error: null,
      role: 'Select',
      succeeded: false,
    },
  ],
});

export const $setUpPersonalInfo = Signal({
  firstName: '',
  lastName: '',
  phoneNumber: '',
});

export const handleOnboardingChooseAccountChange = (accountType) => $onboarding.update({ accountType });
export const handleOnboardingFormChange = (e) => $onboarding.update({ [e.target.name]: e.target.value });

export const fetchAndSetOnboardingUserInvites = async () => {
  try {
    const response = await accruClient.auth.getInvites();
    $onboarding.update({ userInvites: response.items });
    return response.items;
  } catch (error) {
    handleNotification(error, { variant: 'danger' });
  }
};

export const initOnboarding = async () => {
  try {
    $onboarding.loadingStart();

    const { step } = $onboarding.value;
    const { currentOrganization, user } = $user.value;

    await fetchAndSetOnboardingUserInvites();

    $onboarding.update({ firstName: user.first_name, lastName: user.last_name });

    if (isOnboardingUserRequired.value && !isOnboardingOrganizationRequired.value) {
      $onboarding.update({ step: ONBOARDING_STEPS.PERSONAL_INFO });
    } else if (!isOnboardingOrganizationRequired.value) {
      history.replace('/customers');
    }

    if (step !== ONBOARDING_STEPS.COMPLETE &&
      !!currentOrganization.id &&
      !currentOrganization.subscriptionLevel) {
      $onboarding.update({ step: ONBOARDING_STEPS.COMPLETE });
    }
  } catch (error) {
    handleNotification(error, { variant: 'danger' });
  } finally {
    $onboarding.loadingEnd();
  }
};

export const performOnboardingAccountTypeSubmission = (e) => {
  e.preventDefault();
  return $onboarding.update({ step: ONBOARDING_STEPS.PERSONAL_INFO });
};

export const performOnboardingPersonalInfoSubmission = async () => {
  try {
    $user.loadingStart();
    $onboarding.update({ loadingMessage: 'Updating User...' });
    $onboarding.loadingStart();

    const payload = $onboarding.value;
    const response = await accruClient.users.update({
      data: {
        first_name: payload.firstName,
        language: 'en-US',
        last_name: payload.lastName,
        timezone: payload.timezone,
      },
    });

    $user.update({
      user: {
        ...$user.value.user,
        ...response,
      },
    });

    if ($onboarding.value.invitedUser || $user.value.currentOrganization?.id) {
      $onboarding.update({
        step: ONBOARDING_STEPS.COMPLETE,
      });
    } else if ($onboarding.value.userInvites.length) {
      $onboarding.update({ step: ONBOARDING_STEPS.USER_INVITES });
    } else {
      $onboarding.update({ step: ONBOARDING_STEPS.COMPANY_INFO });
    }
  } catch (error) {
    handleNotification(error);
  } finally {
    $user.loadingEnd();
    $onboarding.loadingEnd();
    $onboarding.update({ loadingMessage: 'Loading...' });
  }
};

export const acceptOnboardingInvite = async (inviteId) => {
  try {
    const invite = $onboarding.value.userInvites.find((i) => i.id === inviteId);

    if (!invite) {
      throw new Error('Invite not found.');
    }

    $onboarding.loadingStart();
    $global.update({
      isLoading: true,
      isLoadingMessage: 'Accepting Invite...',
    });

    await accruClient.auth.acceptInvite({
      code: invite.code,
      organizationId: invite.organization.id,
      organizationInviteId: invite.id,
    });

    await fetchAndSetUserData();

    selectOrganization(invite.organization.id);

    $onboarding.update({ step: ONBOARDING_STEPS.COMPLETE });
  } catch (error) {
    handleNotification(error);
  } finally {
    $onboarding.loadingEnd();
    $global.update({
      isLoading: false,
      isLoadingMessage: 'Loading...',
    });
  }
};

export const skipOnboardingUserInvites = () => $onboarding.update({ step: ONBOARDING_STEPS.COMPANY_INFO });

export const createOrganizationSubmit = async (email) => {
  try {
    $onboarding.update({ loadingMessage: 'Creating Organization...' });
    $onboarding.loadingStart();

    const payload = $onboarding.value;

    if (payload.companyName.length === 0) {
      throw new Error('Company Name cannot be blank.');
    }

    if (!payload.companyIndustry || payload.companyIndustry === 'Select') {
      throw new Error('Company Industry cannot be blank.');
    }

    if (!payload.numberOfPeople || payload.numberOfPeople === 'Select') {
      throw new Error('Please select the number of people.');
    }
    if (!formatPhoneNumber($onboarding.value.phoneNumber)) {
      throw new Error('Please enter a valid phone number.');
    }
    await accruClient.users.update({
      data: {
        first_name: payload.firstName,
        last_name: payload.lastName,
        timezone: payload.timezone,
        language: 'en-US',
      },
    });

    const response = await accruClient.organizations.create({
      data: {
        email,
        name: payload.companyName,
        phone_number: formatPhoneNumForApi(payload.phoneNumber),
        primary_contact_name: `${payload.firstName} ${payload.lastName}`,
        business_industry: payload.companyIndustry,
        business_number_of_employees: Number(payload.numberOfPeople),
      },
    });

    if (response) {
      logEvent({ name: 'create_organization_success' });
      await fetchAndSetUserData();
      $onboarding.update({
        organizationId: response.id,
        step: ONBOARDING_STEPS.SEND_INVITES,
      });
    }
  } catch (error) {
    $onboarding.update({ step: ONBOARDING_STEPS.COMPANY_INFO });
    handleNotification(error);
  } finally {
    $onboarding.loadingEnd();
    $onboarding.update({ loadingMessage: 'Loading...' });
  }
};

export const inviteLimitMessage = 'You have reached the maximum number of seats available now. Upgrade your plan on settings later to invite more.';

const NEW_MEMBER = { email: '', role: 'Select', succeeded: false, error: null };

export const fetchAndSetOnboardingOrganizationInvites = async () => {
  try {
    $invitations.reset();
    $invitations.loadingStart();

    const existingInvitesResponse = await accruClient.organizations.getCollaboratorInvites({
      organizationId: $user.value.currentOrganization.id,
    });

    const existingUsersResponse = await accruClient.organizations.getCollaborators({
      organizationId: $user.value.currentOrganization.id,
    });

    $invitations.update({
      existingInvites: existingInvitesResponse.items,
      existingUsers: existingUsersResponse,
    });
  } catch (error) {
    handleNotification(error, { variant: 'danger' });
  } finally {
    $invitations.loadingEnd();
  }
};

export const handleCancelOrganizationOnboardingInvite = async (inviteId) => {
  try {
    $invitations.loadingStart();

    await accruClient.organizations.deleteCollaboratorInvite({
      organizationId: $user.value.currentOrganization?.id,
      organizationInviteId: inviteId,
    });
  } catch (error) {
    handleNotification(error);
  } finally {
    await fetchAndSetOnboardingOrganizationInvites();
    $invitations.loadingEnd();
  }
};

export const handleMemberChange = (e, index, email) => {
  const { members } = $invitations.value;
  const newMembers = [...members].map((m, i) => {
    if (index !== i) {
      return m;
    }

    return { ...m, [email ? 'email' : 'role']: e.target.value };
  });

  return $invitations.update({
    members: newMembers,
  });
};

export const addMember = () => {
  const { members, existingInvites, existingUsers } = $invitations.value;
  const { currentOrganization } = $user.value;

  const requestedSeats = members.length + existingInvites.length + existingUsers.length;
  const canAddInvite = requestedSeats <= currentOrganization.data.subscription_data.organization_user_seats;

  if (!canAddInvite) {
    return handleNotification(inviteLimitMessage);
  }

  $invitations.update({
    members: $invitations.value.members.concat(NEW_MEMBER),
  });
};

export const deleteMember = (e, index) => {
  e.preventDefault();
  return $invitations.update({
    members: [...$invitations.value.members].filter((m, i) => i !== index),
  });
};

export const sendOrganizationOnboardingInvites = async () => {
  try {
    $invitations.loadingStart();
    const members = [...$invitations.value.members];

    members.forEach((member) => {
      if (member.role === 'Select' || member.role === '') {
        throw new Error('Please select a role for each team member.');
      }

      validateEmail(member.email);
    });

    const { existingInvites, existingUsers } = $invitations.value;
    const { currentOrganization } = $user.value;

    const requestedSeats = members.length + existingInvites.length + existingUsers.length;
    const canContinue = requestedSeats <= currentOrganization.data.subscription_data.organization_user_seats;

    if (!canContinue) {
      return handleNotification(inviteLimitMessage);
    }

    await members.reduce(async (promise, member) => {
      await promise;
      return accruClient.organizations.inviteCollaborator({
        data: { email: member.email, role: member.role },
        organizationId: $onboarding.value.organizationId || $user.value.currentOrganization.id,
      });
    }, Promise.resolve());

    $onboarding.update({
      step: ONBOARDING_STEPS.COMPLETE,
    });

    await fetchAndSetUserData();

    logEvent({ name: 'send_invite_success' });
    logEvent({
      name: 'onboarding_complete',
      metadata: {
        onboardingData: $onboarding.value,
      },
      isTrigger: true,
    });
  } catch (error) {
    handleNotification(error);
  } finally {
    $invitations.loadingEnd();
  }
};

export const skipOrganizationOnboardingInvites = async () => {
  await fetchAndSetUserData();
  $onboarding.update({
    step: ONBOARDING_STEPS.COMPLETE,
  });
  logEvent({
    name: 'onboarding_complete',
    metadata: {
      onboardingData: $onboarding.value,
    },
    isTrigger: true,
  });
};

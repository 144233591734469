import { Button, Col, Form, Row } from 'react-bootstrap';
import { useEffect } from 'react';
import PhoneNumberInput from 'components/global/Inputs/PhoneNumberInput';
import $user from 'signals/User.signals';

import { $settingsOrganization, $settingsOrganizationUI } from 'components/views/SettingsOrganization/_helpers/SettingsOrganization.signals';
import { cancelOrganizationContactUpdate, fetchOrganizationData, handleOrganizationSelectCustomerNotificationMode, handleVerifyContactFormChange, updateOrganizationEmailFinish, updateOrganizationEmailStart, updateOrganizationPhone } from 'components/views/SettingsOrganization/_helpers/SettingsOrganization.helpers';
import UniversalInput from 'components/global/Inputs/UniversalInput';
import CustomerNotificationCopy from 'components/views/SettingsOrganization/_components/CustomerNotificationCopy';

const ContactInfo = () => {
  const {
    email,
    verifiedEmail,
    phoneNumber,
    verifiedPhoneNumber,
    contactVerificationCode,
  } = $settingsOrganization.value;
  const { contactPage } = $settingsOrganizationUI.value;

  const { currentOrganization } = $user.value;
  useEffect(async () => {
    if (currentOrganization) {
      await fetchOrganizationData(currentOrganization.id);
    }
  }, [currentOrganization]);

  return (
    <Row className="mx-0">
      <Row className="mx-0 mt-24 py-24 bg-gray-50 rounded">
        {contactPage === 'contact' && (
          <>
            <Form.Group>
              <Form.Label htmlFor="email"><strong>Email</strong></Form.Label>
              <Row className="mx-0 mb-16 align-items-center">
                <Col sm={9} className="p-0">
                  <UniversalInput
                    placeholder="Email address"
                    name="email"
                    signal={$settingsOrganization}
                  />
                  <small className="text-dark mt-2">
                    This email will be used as the reply-to address on notifications sent to customers.
                  </small>
                </Col>
                <Col sm={3} className="p-0 ps-16">
                  <Button
                    variant="primary"
                    onClick={() => updateOrganizationEmailStart()}
                    className="w-100"
                    disabled={!email || email === verifiedEmail || $settingsOrganization.value.isLoading}
                  >
                    UPDATE
                  </Button>
                </Col>
              </Row>
            </Form.Group>
            <CustomerNotificationCopy
              name="receiveCustomerNotificationMode"
              label="Send a copy of notifications sent to customers"
              signal={$settingsOrganization}
              onChange={(value) => handleOrganizationSelectCustomerNotificationMode(value)}
            />
            <Form.Group>
              <Form.Label htmlFor="email"><strong>Phone Number</strong></Form.Label>
              <Row className="mx-0 align-items-center">
                <Col sm={9} className="p-0">
                  <PhoneNumberInput
                    name="phoneNumber"
                    value={phoneNumber}
                    signal={$settingsOrganization}
                  />
                </Col>
                <Col sm={3} className="p-0 ps-16">
                  <Button
                    variant="primary"
                    onClick={() => updateOrganizationPhone()}
                    className="w-100"
                    disabled={!phoneNumber || phoneNumber === verifiedPhoneNumber || $settingsOrganization.value.isLoading}
                  >
                    UPDATE
                  </Button>
                </Col>
              </Row>
            </Form.Group>
          </>
        )}
        {contactPage === 'validation' && (
          <Form.Group>
            <h2 className="fw-700 text-center mb-32">Verify it&apos;s you</h2>
            <p className="base fw-400 text-dark text-center">
              For your security, we just need to make sure this is you. We sent you a 6 digit code to {email}
            </p>
            <Row>
              <div className="px-0 me-8 d-flex justify-content-around">
                {contactVerificationCode.map((value, index) => (
                  <Form.Control
                    key={index}
                    className={`mb-40 h3 mx-8 text-center p-0 py-16 validation-form ${value === '' ? 'active' : ''}`}
                    type="text"
                    max="1"
                    value={value}
                    name="verificationCode"
                    onChange={(e) => handleVerifyContactFormChange(e, index, contactVerificationCode)}
                  />
                ))}
              </div>
            </Row>
            <Row className="mx-0 px-0">
              <Col className="ps-0">
                <Button
                  variant="outline-primary"
                  disabled={$settingsOrganization.value.isLoading}
                  onClick={() => cancelOrganizationContactUpdate()}
                  className="w-100 mb-16 mt-24"
                >
                  CANCEL
                </Button>
              </Col>
              <Col className="pe-0">
                <Button
                  variant="primary"
                  onClick={() => updateOrganizationEmailFinish()}
                  className="w-100 mb-16 mt-24"
                  disabled={$settingsOrganization.value.isLoading || contactVerificationCode.includes('')}
                >
                  CONFIRM
                </Button>
              </Col>
            </Row>
          </Form.Group>
        )}
      </Row>
    </Row>
  );
};

export default ContactInfo;

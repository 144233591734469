import { Card, Col, Row } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/free-solid-svg-icons';

import { ORGANIZATION_SUBSCRIPTION_PLAN_ITEM } from '@accru/client';

import { $settingsSubscriptionActivePaidPlanSubscriptions, subscriptionBaseItemInfoMap } from 'components/views/SettingsSubscription/SettingsSettingsSubscription.helpers';

const PlanCardFreePlan = () => {
  const selectedCardData = subscriptionBaseItemInfoMap[ORGANIZATION_SUBSCRIPTION_PLAN_ITEM.FREE_PLAN];
  const hasSomeActivePaidSubscription = $settingsSubscriptionActivePaidPlanSubscriptions.value.length > 0;

  return (
    <>
      <Card className={`bg-${!hasSomeActivePaidSubscription ? 'info-50' : 'light'} border-primary-50 border p-24`}>
        <Row className="mx-0 mb-16">
          <Col xs={8} className="p-0 text-start">
            <h3 className="mb-0 fw-400">
              <span className="fw-bold">{selectedCardData.title}</span>
            </h3>
          </Col>
          {(!hasSomeActivePaidSubscription) && (
            <Col xs={4} className="p-0 text-end">
              <p className="mb-0 text-primary fw-bold">
                <FontAwesomeIcon icon={faCheck} className="me-8" />
                Active
              </p>
            </Col>
          )}

        </Row>
        <Row>
          <h5 className="fw-300">{selectedCardData.description}</h5>
        </Row>
      </Card>
    </>
  );
};

export default PlanCardFreePlan;

import { accruClient } from 'api';
import Signal from 'signals/Signal';
import { handleNotification } from 'components/global/Alert/Alert';
import { REMINDER_DUE_DATE_MODE, SORT_ORDER } from '@accru/client';
import $user from 'signals/User.signals';
import { effect } from '@preact/signals-react';

export const $settingsReminder = Signal({
  currentTab: 'invoice-reminders',

  dueReminderSettings: null,
  dueReminderSettingsPage: 0,
  overdueReminderSettings: null,
  overdueReminderSettingsPage: 0,

  enableDueReminders: false,
  enableOverdueReminders: false,

  allowSnoozeDueReminders: false,
  allowSnoozeOverdueReminders: false,

  createData: null,
  updateData: null,
  deleteId: null,
});

effect(() => {
  const { previousValue, value: { currentTab } } = $settingsReminder;
  if (!!previousValue && currentTab !== previousValue?.currentTab) {
    $settingsReminder.update({
      createData: null,
      updateData: null,
      deleteId: null,
    });
  }
}, [$settingsReminder]);

export const remindersPerPage = 20;

export const DUE_REMINDER_DEFAULT_DATA = {
  due_date_mode: REMINDER_DUE_DATE_MODE?.BEFORE,
  days_amount: 1,
  selected_hour: 10,

  organization_customer_id: null,
  organization_project_id: null,
  organization_invoice_id: null,
};

export const OVERDUE_REMINDER_DEFAULT_DATA = {
  ...DUE_REMINDER_DEFAULT_DATA,

  due_date_mode: REMINDER_DUE_DATE_MODE?.AFTER,

  repeat_mode: null,
  repeat_value: null,
};

export const getReminderUpdateData = (data, type) => {
  const defaultSettings = type === 'due' ? DUE_REMINDER_DEFAULT_DATA : OVERDUE_REMINDER_DEFAULT_DATA;

  return {
    ...defaultSettings,
    ...Object.entries(data).reduce((acc, [key, value]) => {
      if (defaultSettings[key] !== undefined) { return { ...acc, [key]: value }; }
      return acc;
    }, {}),
    id: data.id,
  };
};

export const fetchAndSetOrganizationBaseSettings = async () => {
  const organizationId = $user?.value?.currentOrganization?.id;
  const organizationBaseSettings = await accruClient.organizations.getSettings({ organizationId });
  $settingsReminder.update({
    enableDueReminders: organizationBaseSettings.setting_send_invoice_due_reminders,
    enableOverdueReminders: organizationBaseSettings.setting_send_invoice_overdue_reminders,

    allowSnoozeDueReminders: organizationBaseSettings.setting_allow_invoice_due_snooze_reminders,
    allowSnoozeOverdueReminders: organizationBaseSettings.setting_allow_invoice_overdue_snooze_reminders,
  });
};

export const handleOrganizationBaseSettingChange = async (type, value) => {
  if (!['enableDueReminders', 'enableOverdueReminders', 'allowSnoozeDueReminders', 'allowSnoozeOverdueReminders'].includes(type)) return;
  if (typeof value !== 'boolean') return;

  $settingsReminder.loadingStart();
  $settingsReminder.update({
    [type]: value,
  });

  const organizationId = $user?.value?.currentOrganization?.id;
  const settings = $settingsReminder.value;

  try {
    await accruClient.reminders.updateGlobalSettings({
      organizationId,
      settingSendInvoiceDueReminders: !!settings.enableDueReminders,
      settingSendInvoiceOverdueReminders: !!settings.enableOverdueReminders,
      settingAllowInvoiceDueSnoozeReminders: !!settings.allowSnoozeDueReminders,
      settingAllowInvoiceOverdueSnoozeReminders: !!settings.allowSnoozeOverdueReminders,
    });
    await fetchAndSetOrganizationBaseSettings();
  } catch (error) {
    handleNotification(error);
  } finally {
    $settingsReminder.loadingEnd();
  }
};

export const fetchAndSetReminderSettings = async (params = {}) => {
  try {
    $settingsReminder.loadingStart();
    const organizationId = $user?.value?.currentOrganization?.id;

    await fetchAndSetOrganizationBaseSettings();

    const sorting = [{
      field: 'created_at',
      order: SORT_ORDER.ASC,
    }, {
      field: 'id',
      order: SORT_ORDER.DESC,
    }];

    const duePage = params.duePage !== undefined ? params.duePage : $settingsReminder.value.dueReminderSettingsPage;
    const overduePage = params.overduePage !== undefined ? params.overduePage : $settingsReminder.value.overdueReminderSettingsPage;

    const dueReminderSettings = await accruClient.reminders.get({
      organizationId,
      dueDateMode: REMINDER_DUE_DATE_MODE?.BEFORE,
      sorting,

      skip: duePage * remindersPerPage,
      take: remindersPerPage,
    });

    const overdueReminderSettings = await accruClient.reminders.get({
      organizationId,
      dueDateMode: REMINDER_DUE_DATE_MODE?.AFTER,
      sorting,

      skip: overduePage * remindersPerPage,
      take: remindersPerPage,
    });

    $settingsReminder.update({
      dueReminderSettings,
      overdueReminderSettings,

      dueReminderSettingsPage: duePage,
      overdueReminderSettingsPage: overduePage,

      createData: null,
      updateData: null,
      deleteId: null,
    });
  } catch (error) {
    handleNotification(error);
  } finally {
    $settingsReminder.loadingEnd();
  }
};

export const createReminder = async () => {
  try {
    $settingsReminder.loadingStart();

    const data = $settingsReminder.value.createData;
    if (!data) return;

    const organizationId = $user?.value?.currentOrganization?.id;

    const reminder = await accruClient.reminders.create({
      organizationId,
      data: {
        ...data,
        due_date_mode: data.due_date_mode === 'BEFORE' ? REMINDER_DUE_DATE_MODE.BEFORE : REMINDER_DUE_DATE_MODE.AFTER,
      },
    });

    $settingsReminder.update({
      ...(data.due_date_mode === REMINDER_DUE_DATE_MODE.BEFORE && {
        dueReminderSettings: {
          ...$settingsReminder.value.dueReminderSettings,
          items: [...$settingsReminder.value.dueReminderSettings.items || [], reminder],
        },
      }),
      ...(data.due_date_mode === REMINDER_DUE_DATE_MODE.AFTER && {
        overdueReminderSettings: {
          ...$settingsReminder.value.overdueReminderSettings,
          items: [...$settingsReminder.value.overdueReminderSettings.items || [], reminder],
        },
      }),

      createData: null,
    });
  } catch (error) {
    handleNotification(error);
  } finally {
    $settingsReminder.loadingEnd();
  }
};

export const updateReminder = async () => {
  try {
    $settingsReminder.loadingStart();

    const { id, ...data } = $settingsReminder.value.updateData;
    if (!id || !data) return;

    const organizationId = $user?.value?.currentOrganization?.id;

    const reminder = await accruClient.reminders.update({
      organizationId,
      organizationReminderSettingId: id,
      data,
    });

    $settingsReminder.update({
      ...(data.due_date_mode === REMINDER_DUE_DATE_MODE.BEFORE && {
        dueReminderSettings: {
          ...$settingsReminder.value.dueReminderSettings,
          items: $settingsReminder.value.dueReminderSettings.items.map(item => (item.id === reminder.id ? reminder : item)),
        },
      }),
      ...(data.due_date_mode === REMINDER_DUE_DATE_MODE.AFTER && {
        overdueReminderSettings: {
          ...$settingsReminder.value.overdueReminderSettings,
          items: $settingsReminder.value.overdueReminderSettings.items.map(item => (item.id === reminder.id ? reminder : item)),
        },
      }),

      updateData: null,
    });
  } catch (error) {
    handleNotification(error);
  } finally {
    $settingsReminder.loadingEnd();
  }
};

export const deleteReminder = async () => {
  try {
    const organizationReminderSettingId = $settingsReminder.value.deleteId;
    if (!organizationReminderSettingId) return;

    $settingsReminder.loadingStart();

    const organizationId = $user?.value?.currentOrganization?.id;

    await accruClient.reminders.del({ organizationId, organizationReminderSettingId });

    await fetchAndSetReminderSettings();
  } catch (error) {
    handleNotification(error);
  } finally {
    $settingsReminder.loadingEnd();
  }
};

export const addOrdinalSuffix = (number) => {
  if (number % 100 >= 11 && number % 100 <= 13) {
    return `${number}th`;
  }
  const lastDigit = number % 10;
  switch (lastDigit) {
    case 1:
      return `${number}st`;
    case 2:
      return `${number}nd`;
    case 3:
      return `${number}rd`;
    default:
      return `${number}th`;
  }
};

export const getLabelByValue = (isWeekly, value) => {
  if (isWeekly) {
    const daysOfWeek = [
      { label: 'Sunday', value: 0 },
      { label: 'Monday', value: 1 },
      { label: 'Tuesday', value: 2 },
      { label: 'Wednesday', value: 3 },
      { label: 'Thursday', value: 4 },
      { label: 'Friday', value: 5 },
      { label: 'Saturday', value: 6 },
    ];
    for (let i = 0; i < daysOfWeek.length; i++) {
      if (daysOfWeek[i].value === value) {
        return `on ${daysOfWeek[i].label}`;
      }
    }
  } else {
    const monthOptions = Array.from(Array(31).keys()).map((i) => ({ label: addOrdinalSuffix(i + 1), value: i + 1 }));
    for (let i = 0; i < monthOptions.length; i++) {
      if (monthOptions[i].value === value) {
        return `on the ${monthOptions[i].label}`;
      }
    }
  }
};

/* eslint-disable no-alert */
import { useCallback, useMemo } from 'react';
import { $alert } from 'signals/Global.signals';
import Toast from 'components/global/Alert/Toast';
import AlertModal from 'components/global/Alert/AlertModal';

const ToastContainer = ({ onRemoveNotification }) => (
  <div className="z-index-9999 container-fliud fixed-top mt-24">
    {$alert.value.notificationArray.map(notification => (
      <Toast notification={notification} key={notification.id} onRemoveNotification={onRemoveNotification} />
    ))}
  </div>
);

export const handleNotification = (notification, overrides) => {
  try {
    const allNotifications = [...$alert.value.notificationArray] || [];

    if (notification.graphQLErrors?.length) { // handle apollo errors from SDK
      notification.graphQLErrors.forEach(e => {
        console.error(notification);
        const newNotification = {
          ...e,
          variant: overrides?.variant || e.variant || 'warning',
          displayType: overrides?.displayType || e.displayType || 'toast',
          id: `${Date.now()}-${Math.floor(Math.random() * 10000)}`,
        };
        allNotifications.push(newNotification);
      });
    } else if (notification.networkError && Object.keys(notification.networkError).length) { // handle network errors from SDK
      console.error(notification);
      allNotifications.push({
        variant: 'danger',
        displayType: 'toast',
        id: `${Date.now()}-${Math.floor(Math.random() * 10000)}`,
      });
    } else { // handle FE validation errors, etc
      const newNotification = {
        variant: notification.variant || overrides?.variant || 'warning',
        displayType: notification.displayType || overrides?.displayType || 'toast',
        displayMessage: notification.message || notification || 'An unknown error occurred - please try again',
        id: `${Date.now()}-${Math.floor(Math.random() * 10000)}`,
      };
      if (newNotification.variant !== 'success') {
        console.error(notification);
      }
      allNotifications.push(newNotification);
    }
    $alert.update({ notificationArray: allNotifications });
  } catch (error) {
    console.error('Error in handleNotification:', { notification, overrides, error });
  }
};

const Alert = () => {
  const { notificationArray } = $alert.value;

  const handleRemoveAlert = useCallback((id) => {
    const filteredAlerts = $alert.value.notificationArray.filter(alert => alert.id !== id);
    $alert.update({ notificationArray: filteredAlerts });
  }, []);

  const sortedNotifications = useMemo(() => {
    const grouped = {
      modal: [],
      toast: [],
      window: [],
    };
    notificationArray.forEach(notification => {
      if (grouped[notification.displayType]) {
        grouped[notification.displayType]?.push(notification);
      }
    });
    return grouped;
  }, [notificationArray.length]);

  const devNotifications = notificationArray.filter(n => n.displayType === 'dev');
  if (devNotifications.length) {
    devNotifications.forEach(n => console.error(n));
  }

  if (sortedNotifications.window.length) {
    window.alert(sortedNotifications.window[0].message);
    $alert.reset();
    return null;
  }

  if (sortedNotifications.modal.length) {
    return <AlertModal notification={sortedNotifications.modal[0]} onRemoveNotification={handleRemoveAlert} />;
  }

  if (sortedNotifications.toast.length) {
    return <ToastContainer notifications={sortedNotifications.toast} onRemoveNotification={handleRemoveAlert} />;
  }

  return <div />;
};

export default Alert;

import { Button, Card, Col, Row } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPencil, faXmark } from '@fortawesome/free-solid-svg-icons';
import UniversalInput from 'components/global/Inputs/UniversalInput';
import { $settingsReminder, addOrdinalSuffix, createReminder, getLabelByValue, getReminderUpdateData, remindersPerPage, updateReminder } from 'components/views/SettingsReminders/SettingsReminders.helpers';
import { REMINDER_REPEAT_MODE } from '@accru/client';
import SelectInput from 'components/global/Inputs/SelectInput';

const ReminderCard = ({ reminder, index, dueType, displayMode }) => {
  const {
    isLoading,

    dueReminderSettingsPage,
    overdueReminderSettingsPage,

    createData,
    updateData,
  } = $settingsReminder.value;

  const isCreateMode = displayMode === 'create';
  const isUpdateMode = !isCreateMode && updateData?.id === reminder?.id;

  const isEditing = isCreateMode || isUpdateMode;

  const targetEditKey = displayMode === 'create' ? 'createData' : 'updateData';
  const targetEditObj = isCreateMode ? createData : updateData;

  const source = isEditing ? targetEditObj : reminder;

  return (
    <Card className="border-gray-100 p-16 mt-16">
      <Row className="m-0 d-flex justify-content-between">
        <Col className="p-0 d-flex justify-content-start align-items-center">
          <h3 className="mb-0 fw-700">{dueType === 'due' ? 'Reminder' : 'Overdue Reminder'} {(index + ((dueType === 'due' ? dueReminderSettingsPage : overdueReminderSettingsPage) * remindersPerPage)) + 1}</h3>
        </Col>
        {!isCreateMode && (
          <Col className="p-0 d-flex justify-content-end align-items-center">
            {!isEditing ? (
              <Button
                className="mb-0 text-primary"
                variant="eye"
                disabled={isLoading}
                onClick={() => $settingsReminder.update({ updateData: getReminderUpdateData(reminder, dueType) })}
              >
                <FontAwesomeIcon icon={faPencil} /> Edit / remove
              </Button>
            ) : (
              <Button
                className="mb-0 text-danger"
                variant="eye"
                disabled={isLoading}
                onClick={() => $settingsReminder.update({ deleteId: reminder.id })}
              >
                <FontAwesomeIcon icon={faXmark} /> Remove
              </Button>
            )}
          </Col>
        )}
      </Row>
      <Row className="m-0">
        <Col sm={1} className="p-0" hidden={!isEditing}>
          <UniversalInput
            placeholder="1"
            type="number"
            name="days_amount"
            hidden={!isEditing}
            signal={$settingsReminder}
            disabled={isLoading || !isEditing}
            customValue={source.days_amount}
            customOnChange={e => $settingsReminder.update({ [targetEditKey]: { ...targetEditObj, days_amount: Number(e.target.value) } })}
          />
        </Col>
        <Col sm={11} className={`d-flex align-items-bottom ${isEditing ? 'ps-8' : 'ps-0'}`}>
          <p className="mt-auto mb-0">
            {!isEditing ? `${source.days_amount} ` : ''}
            day(s) {dueType === 'due' ? 'before' : 'after'} due date
          </p>
        </Col>
      </Row>
      {dueType === 'overdue' && (
        <>
          <Row className="row-cols-auto">
            <Col className="mt-8 d-flex align-items-bottom">
              <p className="p-0 pe-8 mt-auto mb-0">
                Repeat
              </p>
              {isEditing ? (
                <SelectInput
                  name="repeat_mode"
                  signal={$settingsReminder}
                  value={source.repeat_mode}
                  options={[
                    { label: 'Never', value: null },
                    { label: 'Daily', value: REMINDER_REPEAT_MODE.DAILY },
                    { label: 'Weekly', value: REMINDER_REPEAT_MODE.WEEKLY },
                    { label: 'Monthly', value: REMINDER_REPEAT_MODE.MONTHLY },
                  ]}
                  disabled={isLoading || !isEditing}
                  customOnChange={(e) => {
                    $settingsReminder.update({
                      [targetEditKey]: {
                        ...targetEditObj,
                        repeat_mode: Object.values(REMINDER_REPEAT_MODE).includes(e.target.value) ? e.target.value : null,

                        ...(!Object.values(REMINDER_REPEAT_MODE).includes(e.target.value) && { repeat_value: null }),
                        ...(e.target.value === REMINDER_REPEAT_MODE.DAILY && { repeat_value: 10 }), // at 10am (24h format)
                        ...(e.target.value === REMINDER_REPEAT_MODE.WEEKLY && { repeat_value: 5 }), // 0 is sunday
                        ...(e.target.value === REMINDER_REPEAT_MODE.MONTHLY && { repeat_value: 5 }), // 1-31 day of the month
                      },
                    });
                  }}
                />
              ) : (
                <p className="p-0 mt-auto mb-0 fw-900">
                  {source.repeat_mode}
                </p>
              )}
              {isEditing && (
                <p className="p-0 px-8 mb-0 mt-auto">
                  on
                </p>
              )}
              <>
                {isEditing ? (
                  <>
                    {source.repeat_mode === REMINDER_REPEAT_MODE.WEEKLY && (
                      <SelectInput
                        name="repeat_value"
                        signal={$settingsReminder}
                        value={source.repeat_value}
                        options={[
                          { label: 'Sunday', value: 0 },
                          { label: 'Monday', value: 1 },
                          { label: 'Tuesday', value: 2 },
                          { label: 'Wednesday', value: 3 },
                          { label: 'Thursday', value: 4 },
                          { label: 'Friday', value: 5 },
                          { label: 'Saturday', value: 6 },
                        ]}
                        disabled={isLoading || !isEditing}
                        customOnChange={(e) => {
                          $settingsReminder.update({
                            [targetEditKey]: {
                              ...targetEditObj,
                              repeat_value: Number(e.target.value),
                            },
                          });
                        }}
                      />
                    )}
                    {source.repeat_mode === REMINDER_REPEAT_MODE.MONTHLY && (
                      <SelectInput
                        name="repeat_value"
                        signal={$settingsReminder}
                        value={source.repeat_value}
                        options={Array.from(Array(31).keys()).map((i) => ({ label: addOrdinalSuffix(i + 1), value: i + 1 }))}
                        disabled={isLoading || !isEditing}
                        customOnChange={(e) => {
                          $settingsReminder.update({
                            [targetEditKey]: {
                              ...targetEditObj,
                              repeat_value: Number(e.target.value),
                            },
                          });
                        }}
                      />
                    )}
                  </>
                ) : (
                  <p className="p-0 mt-auto mb-0 fw-900 ps-8">
                    {[REMINDER_REPEAT_MODE.WEEKLY, REMINDER_REPEAT_MODE.MONTHLY].includes(source.repeat_mode) ? getLabelByValue(source.repeat_mode === REMINDER_REPEAT_MODE.WEEKLY, source.repeat_value) : ''}
                  </p>
                )}
              </>

            </Col>
          </Row>
        </>
      )}
      {isEditing && (
        <Row className="pt-8 justify-content-end">
          <Col className="col-auto mr-auto p-0">
            <Button
              variant="outline-primary"
              disabled={isLoading}
              onClick={() => $settingsReminder.update({ [targetEditKey]: null })}
            >
              CANCEL
            </Button>
          </Col>
          <Col className="col-auto ps-8">
            <Button
              variant="primary"
              disabled={isLoading}
              onClick={() => (isCreateMode ? createReminder() : updateReminder())}
            >
              SAVE
            </Button>
          </Col>
        </Row>
      )}
    </Card>
  );
};

export default ReminderCard;

import { useCallback } from 'react';
import { Col, Form, Row } from 'react-bootstrap';

import Loadable from 'components/global/Loadable';
import { RECEIVE_CUSTOMER_NOTIFICATION_MODE } from '@accru/client';

const CustomerNotificationCopy = ({ name, label, signal, onChange }) => {
  const handleChange = useCallback((value) => {
    if (typeof onChange === 'function') { onChange(value); }
    signal.update({ [name]: value });
  }, [signal, onChange]);

  return (
    <Form.Group>
      <Row className="mb-16">
        <strong className="mb-8">{label}</strong>
        <Loadable signal={signal}>
          <Col sm={4} className="d-flex align-items-center text-dark">
            <Form.Check
              inline
              id="customerNotificationsOff"
              className="text-dark"
              type="radio"
              disabled={signal.value.isLoading}
              checked={!signal.value[name]}
              onClick={() => handleChange(null)}
            />
            <Form.Label htmlFor="customerNotificationsOff" className="m-0">Do not send</Form.Label>
          </Col>
          <Col sm={4} className="d-flex align-items-center text-dark">
            <Form.Check
              inline
              id="customerNotificationsBcc"
              type="radio"
              disabled={signal.value.isLoading}
              checked={signal.value[name] === RECEIVE_CUSTOMER_NOTIFICATION_MODE.BCC}
              onClick={() => handleChange(RECEIVE_CUSTOMER_NOTIFICATION_MODE.BCC)}
            />
            <Form.Label htmlFor="customerNotificationsBcc" className="m-0">Send as BCC</Form.Label>
          </Col>
          <Col sm={4} className="d-flex align-items-center text-dark">
            <Form.Check
              inline
              id="customerNotificationsCc"
              type="radio"
              disabled={signal.value.isLoading}
              checked={signal.value[name] === RECEIVE_CUSTOMER_NOTIFICATION_MODE.TO}
              onClick={() => handleChange(RECEIVE_CUSTOMER_NOTIFICATION_MODE.TO)}
            />
            <Form.Label htmlFor="customerNotificationsCc" className="m-0">Send TO</Form.Label>
          </Col>
        </Loadable>
      </Row>
    </Form.Group>
  );
};

export default CustomerNotificationCopy;

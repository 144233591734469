import $user from 'signals/User.signals';
import { analytics, firestore } from './firebase';

if (process.env.NODE_ENV === 'development') {
  firestore.useEmulator('localhost', 8080);
}

// ALL TRIGGERED EVENTS SHOULD BE LISTED HERE
/*
  onboarding_complete
  first_sent_invoice
  first_sync
*/

const logEvent = async ({ name, metadata = {}, isTrigger = true }) => {
  if (process.env.NODE_ENV === 'development') return;
  if (!name) {
    return;
  }

  try {
    const userEmail = $user.value?.user?.email;
    const eventData = {
      name,
      metadata,
      user: $user.value,
    };

    if (isTrigger && userEmail) {
      firestore
        .collection('private_user_events')
        .doc(userEmail)
        .collection(name)
        .doc()
        .set(eventData);
    } else {
      firestore
        .collection('public_user_events')
        .doc(name || 'no-user')
        .set({
          name: metadata || 'no-user',
          metadata: metadata || null,
          user: null,
        });
    }

    analytics.logEvent(name, metadata);
  } catch (e) {
    console.info(`Cannot log event ${name}: ${e}`);
  }
};

export const setAnalyticsUserProperty = (property) => {
  try {
    if (process.env.NODE_ENV !== 'development') {
      analytics.setUserProperties(property);
    }
  } catch (e) {
    console.info(`Cannot set analytics user property ${JSON.stringify(property)}: ${e}`);
  }
};

export default logEvent;

import { Col, Row, Button, Offcanvas, Image, Container, Form } from 'react-bootstrap';
import { $customerDetail, $customerStatement } from 'components/views/Customers/_helpers/Customers.signals';
import copyLink from 'images/copyLink.svg';
import addMore from 'images/addMore.svg';
import sentEmailSuccess from 'images/sentEmailSuccess.svg';
import { sendCustomerStatementEmails, handleGetStatementLink, handleAddCustomerContactFromStatement } from 'components/views/Customers/_helpers/Customers.helpers';
import UniversalInput from 'components/global/Inputs/UniversalInput';
import PhoneNumberInput from 'components/global/Inputs/PhoneNumberInput';
import { $syncing } from 'signals/Global.signals';
import { useState } from 'react';
import SimpleModal from 'components/global/SimpleModal/SimpleModal';
import { handleNotification } from 'components/global/Alert/Alert';

const CustomerStatementSidePanel = () => {
  // Todo: Add contact ID to the primary contact
  const { id } = $customerDetail.value;
  const contacts = [
    { name: 'Primary Contact', email: $customerDetail.value.email, id: 'PRIMARY' },
    ...$customerDetail.value.contacts,
  ];
  const { selectedStatementContacts, isStatementSendSuccessModalOpen, isContactFormOpen } = $customerStatement.value;
  const [isProcessing, setIsProcessing] = useState(false);

  const handleSelectAll = (e) => {
    if (e.target.checked) {
      $customerStatement.update({ selectedStatementContacts: contacts });
    } else {
      $customerStatement.update({ selectedStatementContacts: [] });
    }
  };

  const handleSelectContact = (contact) => {
    $customerStatement.update({ selectedStatementContacts: selectedStatementContacts.find(c => c.id === contact.id) ? selectedStatementContacts.filter(c => c.id !== contact.id) : [...selectedStatementContacts, contact] });
  };

  return (
    <Offcanvas
      placement="end"
      show={$customerStatement.value.isStatementSidebarOpen}
      onHide={() => $customerStatement.update({ isStatementSidebarOpen: false, selectedStatementContacts: [] })}
    >
      <Offcanvas.Header closeButton />
      <Offcanvas.Body className="px-0">
        <Container className="px-64 mb-64">
          <Row>
            <Col className="d-flex align-items-center">
              <h2 className="fw-700 mb-0">Share Statement</h2>
            </Col>
            <Col className="d-flex justify-content-end">
              <Button
                variant="transparent"
                className="border-0 px-8 me-8"
                disabled={$syncing.value.isSyncing || isProcessing}
                onClick={async () => {
                  const link = await handleGetStatementLink(id);
                  await navigator.clipboard.writeText(link);
                  setIsProcessing(true);
                  handleNotification('Link copied!', { variant: 'success' });
                  setTimeout(() => setIsProcessing(false), 2000);
                }}
              >
                <div className="mx-auto d-flex align-items-center justify-content-center">
                  <Image src={copyLink} />
                </div>
              </Button>
              <Button
                onClick={() => {
                  setIsProcessing(true);
                  sendCustomerStatementEmails({ customerId: id, customerContacts: selectedStatementContacts?.map(c => c.id) });
                  setTimeout(() => setIsProcessing(false), 2000);
                }}
                disabled={$syncing.value.isSyncing || isProcessing || !selectedStatementContacts.length}
              >
                SHARE STATEMENT
              </Button>
            </Col>
          </Row>
        </Container>
        <Container fluid className="bg-gray-200 py-16 px-0">
          <Container className="px-64">
            <Row>
              <Col sm={1}>
                <Form.Check
                  className="lead fw-800"
                  type="checkbox"
                  onChange={(e) => handleSelectAll(e)}
                />
              </Col>
              <Col className="d-flex align-items-center">
                <div className="small fw-900 text-primary">
                  Name
                </div>
              </Col>
              <Col className="d-flex align-items-center">
                <div className="small fw-900 text-primary">
                  Email
                </div>
              </Col>
            </Row>
          </Container>
        </Container>
        {contacts?.map(contact => (
          <Container className="px-64 border-bottom">
            <Row className="py-16 d-flex align-items-center">
              <Col sm={1}>
                <Form.Check
                  className="lead fw-800"
                  type="checkbox"
                  checked={selectedStatementContacts.find(c => c.id === contact.id)}
                  onChange={() => handleSelectContact(contact)}
                />
              </Col>
              <Col>
                <h5 className="text-dark mb-0">{contact.name}</h5>
              </Col>
              <Col>
                <div className="base text-dark">
                  {contact.email}
                </div>
              </Col>
            </Row>
          </Container>
        ))}
        <Container className="px-64">
          {isContactFormOpen ? (
            <>
              <Row className="mb-40">
                <Col className="mt-16 pe-0">
                  <UniversalInput
                    placeholder="Name"
                    name="contactFormName"
                    signal={$customerStatement}
                  />
                </Col>
                <Col className="mt-16 pe-0">
                  <UniversalInput
                    placeholder="Email address"
                    name="contactFormEmail"
                    signal={$customerStatement}
                  />
                </Col>
                <Col className="mt-16 pe-0">
                  <PhoneNumberInput
                    placeholder="Phone number (optional)"
                    name="contactFormPhoneNo"
                    signal={$customerStatement}
                    className="mb-16"
                  />
                </Col>
              </Row>
              <Row className="my-32">
                <Col sm={{ offset: 5 }} className="d-flex justify-content-between">
                  <Button
                    variant="outline-primary"
                    className="py-8 me-16 w-50"
                    onClick={() => $customerStatement.update({ isContactFormOpen: false })}
                  >
                    CANCEL
                  </Button>
                  <Button
                    className="py-8 w-50"
                    onClick={() => handleAddCustomerContactFromStatement()}
                    disabled={!$customerStatement.value.contactFormEmail || !$customerStatement.value.contactFormName}
                  >
                    SAVE
                  </Button>
                </Col>
              </Row>
            </>
          ) : (
            <Row className="d-flex justify-content-start my-24 my-md-48">
              <div
                className="d-flex cursor-pointer"
                tabIndex={0}
                role="button"
                onClick={() => $customerStatement.update({ isContactFormOpen: true })}
              >
                <Image className="text-start" height={18} src={addMore} />
                <div className="ms-8">Add more</div>
              </div>
            </Row>
          )}
        </Container>
      </Offcanvas.Body>
      <SimpleModal
        isShow={isStatementSendSuccessModalOpen}
        title="Thank you!"
        onClose={() => $customerStatement.update({ isStatementSendSuccessModalOpen: false, isStatementSidebarOpen: false, selectedStatementContacts: [] })}
        onCancel={() => $customerStatement.update({ isStatementSendSuccessModalOpen: false, isStatementSidebarOpen: false, selectedStatementContacts: [] })}
        isHideSave
        cancelText="Close"
      >
        <div className="d-flex justify-content-center flex-column text-center">
          <Image src={sentEmailSuccess} className="w-auto" height="80px" />
          <h4 className="mt-24 fw-400 mb-16">We have just shared your statement with:</h4>
          {selectedStatementContacts.map(contact => (
            <div className="lead text-primary fw-800">{contact.email}</div>
          ))}
        </div>
      </SimpleModal>
    </Offcanvas>
  );
};

export default CustomerStatementSidePanel;

import { useEffect, useMemo } from 'react';
import { AuthLayout } from 'components/global/Layout';
import { $onboarding, initOnboarding, ONBOARDING_STEPS } from 'components/views/Onboarding/Onboarding.helpers';
import CompletedOnboarding from 'components/views/Onboarding/_components/CompletedOnboarding';
import UserInvitesOnboarding from 'components/views/Onboarding/_components/UserInvitesOnboarding';
import InviteMembersForm from 'components/views/Onboarding/_components/InviteMembersForm';
import Loader from 'components/global/Loader';
import AccountTypeForm from './_components/AccountTypeForm';
import PersonalInfoForm from './_components/PersonalInfoForm';
import CompanyInfoForm from './_components/CompanyInfoForm';

const Onboarding = () => {
  const { step } = $onboarding.value;

  useEffect(() => {
    initOnboarding();
  }, []);

  const component = useMemo(() => {
    switch (step) {
      case ONBOARDING_STEPS.ACCOUNT_TYPE:
        return <AccountTypeForm />;
      case ONBOARDING_STEPS.PERSONAL_INFO:
        return <PersonalInfoForm />;
      case ONBOARDING_STEPS.USER_INVITES:
        return <UserInvitesOnboarding />;
      case ONBOARDING_STEPS.COMPANY_INFO:
        return <CompanyInfoForm />;
      case ONBOARDING_STEPS.SEND_INVITES:
        return <InviteMembersForm />;
      case ONBOARDING_STEPS.COMPLETE:
        return <CompletedOnboarding />;
      default:
        return null;
    }
  }, [step]);

  return (
    <AuthLayout>
      {$onboarding.value.isLoading ? <div className="text-center my-128"><Loader message={$onboarding.value.loadingMessage} /></div> : component}
    </AuthLayout>
  );
};

export default Onboarding;

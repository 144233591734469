import { $onboarding } from 'components/views/Onboarding/Onboarding.helpers';
import { useEffect } from 'react';
import { $loader } from 'signals/Global.signals';
import { Row, Col, Button } from 'react-bootstrap';
import $user from 'signals/User.signals';
import logEvent from 'utils/logEvent';
import Loader from 'components/global/Loader';

const CompletedOnboarding = () => {
  const { step } = $onboarding.value;
  const { currentOrganization } = $user.value;

  useEffect(() => {
    let timeoutId;
    if (step === 'complete' && currentOrganization.subscriptionLevel) {
      $loader.update({
        isLoadingMessage: '',
      });
      timeoutId = setTimeout(() => {
        $loader.reset();
        window.location.href = '/customers';
      }, 10000);
    }
    return () => {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
    };
  }, [step]);

  return (
    <>
      {currentOrganization.subscriptionLevel ? (
        <div className="text-center fw-700">
          <Loader className="my-24" />
          <h2 className="mb-24 text-primary">Woohoo! You did it!</h2>
          <div className="lead text-dark mb-40">Give us a minute while we are setting up your account</div>
        </div>
      ) : (
        <>
          <h2 className="fw-700 text-center mb-24 mt-32 text-primary">Want to access Accru <br />FREE for a Year?</h2>
          <div className="lead text-center text-dark mb-40 fw-400">
            Thank you for joining our waitlist! As a special thank you, we&apos;re offering <u>one year of free access</u> to Accru for our most active supporters.
          </div>
          <Row className="mb-32">
            <Col className="text-center">
              <Button
                onClick={() => {
                  logEvent({
                    name: 'kingsumo_click',
                    metadata: {
                      onboardingData: $onboarding.value,
                    },
                  });
                }}
                href="https://kingsumo.com/g/ztmu85/win-a-year-of-accru/wq55zl0"
                target="_blank"
                variant="outline-primary"
              >
                View Details
              </Button>
              <div className="mt-8"><small><i>Be sure to use the same email you signed up with.</i></small></div>
            </Col>
          </Row>
        </>
      )}
    </>
  );
};

export default CompletedOnboarding;

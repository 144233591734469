/* eslint-disable no-unreachable */
import { ORGANIZATION_ACCT_PROVIDER_CONN_STATUS } from '@accru/client';
import { accruClient } from 'api';
import isValidUrl from 'utils/isValidUrl';

export async function fetchPDFBase64({
  item,
  viewerMode,
  acctProviderName,
  currentOrganizationId,
  unconnectedRequestData,
  targetId,
}) {
  let pdfBase64 = null;
  if (viewerMode === 'VENDOR') {
    pdfBase64 = await accruClient.invoices.getAcctProviderPdf({
      acctProvider: acctProviderName,
      organizationId: currentOrganizationId,
      organizationInvoiceId: item.id,
    });
  } else if (viewerMode === 'CUSTOMER') {
    pdfBase64 = await accruClient.statements.getInvoicePdf({
      organizationId: currentOrganizationId,
      organizationVendorId: targetId,
      acctProvider: acctProviderName,
      organizationInvoiceId: item.id,
    });
  } else if (viewerMode === 'UNCONNECTED_CUSTOMER' && unconnectedRequestData) {
    pdfBase64 = await accruClient.invoices.getUnconnectedPdf({
      uniqueCode: targetId,
      email: unconnectedRequestData.email,
      token: unconnectedRequestData.token,
      organizationCustomerStatementLineId: Number(item.id),
      acctProvider: acctProviderName,
    });
  } else {
    throw new Error('Fail to setup view/pay.');
  }

  return pdfBase64;
}

export function getPaymentLink({ item }) {
  const selectedPaymentOption = item.payment_options.find(option => !!option.url);
  if (selectedPaymentOption && isValidUrl(selectedPaymentOption.url)) {
    return selectedPaymentOption.url;
  }
  throw new Error('Invalid payment URL - please contact your accounting provider');
}

export function getAccountProviderName({ item }) {
  const connectedItems = item.organization_acct_provider_conn_invoices;

  if (!connectedItems?.length) {
    throw new Error('No view/pay option available.');
  }

  const obj = connectedItems?.find(itemConn => {
    const isConnected = String(itemConn.organization_acct_provider_conn.status) === String(ORGANIZATION_ACCT_PROVIDER_CONN_STATUS.CONNECTED);
    return isConnected;
  });
  if (!obj) {
    throw new Error('No view/pay option available.');
  }

  return obj.acct_provider;
}

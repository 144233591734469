import { accruClient } from 'api';
import { ACCT_PROVIDER } from '@accru/client';
import Signal from 'signals/Signal';
import { handleNotification } from 'components/global/Alert/Alert';
import quickbooksLogo from 'images/quickbooks-logo.png';
import $user from 'signals/User.signals';
import { $syncing } from 'signals/Global.signals';

export const availableAcctIntegrations = [
  {
    provider: ACCT_PROVIDER?.QUICKBOOKS,
    name: 'QuickBooks',
    disconnectedText: 'Connecting with QuickBooks streamlines financial processes, minimizes errors, and enables real-time synchronization, thereby enhancing efficiency and accuracy for comprehensive financial management.',
    connectedText: 'Accru seamlessly syncs with QuickBooks automatically, providing users with real-time updates and ensuring accurate and up-to-date financial information for enhanced decision-making.',
    logo: quickbooksLogo,
  },
];

export const matchingConfigLabels = {
  [ACCT_PROVIDER?.QUICKBOOKS]: {
    pull_configuration: {},
    push_configuration: {
      default_invoice_item: {
        label: 'Default Invoice Item',
        description: "When you create an invoice in Accru, the invoice item on QuickBooks will be set to this value when it's pushed.",
      },
      default_bill_expense_account: {
        label: 'Default Bill Expense Account',
        description: "When you create a bill in Accru, the expense account on QuickBooks will be set to this value when it's pushed.",
      },
    },
  },
};

export const $settingsAccounting = Signal({
  connections: null,
  disconnectedProviderCode: null,
});

export const $accountingOAuth = Signal({
  provider: null,
  oAuthUrl: null,
  oAuthCallbackUrl: null,
  connection: null,
});

export const $accountingConnSettings = Signal({
  settingOptions: null,
  updateData: {
    automatic_pull_enabled: true,
  },
  connection: null,
});

/**
 * @param {ACCT_PROVIDER} provider
 */
export const isAcctProviderCodeValid = (provider) => !!provider && Object.values(ACCT_PROVIDER).indexOf(provider) !== -1;

/**
 * @param {ACCT_PROVIDER} provider
 */
export const validateAcctProviderCode = (provider) => {
  if (!isAcctProviderCodeValid(provider)) throw new Error('Invalid provider code');
};

export const isValidAcctConnUpdateData = () => {
  const { connection, updateData, settingOptions } = $accountingConnSettings.value;

  if (!connection || !updateData || !settingOptions || connection.id !== updateData.id || !isAcctProviderCodeValid(connection.acct_provider)) return false;

  if (typeof updateData.automatic_pull_enabled !== 'boolean' || typeof updateData.automatic_push_enabled !== 'boolean') return false;

  if (!settingOptions.pull_configuration || !updateData.pull_configuration ||
    !settingOptions.push_configuration || !updateData.push_configuration) return false;

  if (!Object.entries(settingOptions.pull_configuration).every(([key, values]) => {
    if (!updateData.pull_configuration[key] || !values.some(option => option.value === updateData.pull_configuration[key])) return false;
    return true;
  })) return false;

  if (!!updateData.automatic_push_enabled && !Object.entries(settingOptions.push_configuration).every(([key, values]) => {
    if (!updateData.push_configuration[key] || !values.some(option => option.value === updateData.push_configuration[key])) return false;
    return true;
  })) return false;

  return true;
};

export const fetchAndSetOrganizationAcctProviders = async () => {
  try {
    $settingsAccounting.loadingStart();

    const organizationId = $user?.value?.currentOrganization?.id;

    const connections = await accruClient.accountingProviders.get({
      organizationId,
    });

    $settingsAccounting.update({
      connections,
    });
  } catch (error) {
    handleNotification(error);
  } finally {
    $settingsAccounting.loadingEnd();
  }
};

/**
 * @param {ACCT_PROVIDER} provider
 */
export const getAccountingOAuthUrl = async (provider) => {
  validateAcctProviderCode(provider);

  try {
    const organizationId = $user?.value?.currentOrganization?.id;

    const oAuthUrl = await accruClient.accountingProviders.getOAuthUrl({
      organizationId,
      accountProvider: provider,
    });

    return oAuthUrl;
  } catch (error) {
    handleNotification(error);
  }
};

export const getAccountingConnection = async (connId) => {
  try {
    const organizationId = $user?.value?.currentOrganization?.id;
    const connection = await accruClient.accountingProviders.getOne({
      organizationId,
      organizationAcctProviderConnId: connId,
    });
    return connection;
  } catch { return null; }
};

export const getAccountingSettingOptions = async (provider) => {
  const organizationId = $user?.value?.currentOrganization?.id;

  const pullOptions = await accruClient.accountingProviders.getPullOptions({
    organizationId,
    accountProvider: provider,
  });

  const pushOptions = await accruClient.accountingProviders.getPushOptions({
    organizationId,
    accountProvider: provider,
  });

  return {
    pullOptions,
    pushOptions,
  };
};

export const handleAccountingOAuthCallback = async () => {
  const data = $accountingOAuth.value;

  if (!data || !data.provider || !data.oAuthUrl || !data.oAuthCallbackUrl) return;
  if (!isAcctProviderCodeValid(data.provider)) return;

  try {
    $accountingOAuth.loadingStart();
    const organizationId = $user?.value?.currentOrganization?.id;

    const connection = await accruClient.accountingProviders.connect({
      organizationId,
      url: data.oAuthCallbackUrl,
      accountProvider: data.provider,
      automaticPull: false,
      automaticPush: false,
    });

    const { pullOptions, pushOptions } = await getAccountingSettingOptions(connection.acct_provider);

    $accountingOAuth.update({
      connection,
    });

    $accountingConnSettings.reset();
    $accountingConnSettings.update({
      connection,
      settingOptions: {
        pull_configuration: pullOptions,
        push_configuration: pushOptions,
      },
      updateData: {
        id: connection.id,
        automatic_pull_enabled: connection.automatic_pull_enabled,
        automatic_push_enabled: connection.automatic_push_enabled,
        pull_configuration: connection.payload?.pull_configuration || {},
        push_configuration: connection.payload?.push_configuration || {},
      },
    });

    if ($settingsAccounting.value?.connections?.items) {
      $settingsAccounting.update({
        connections: {
          ...$settingsAccounting.value.connections,
          items: $settingsAccounting.value.connections.items.some(c => c.acct_provider === data.provider) ?
            $settingsAccounting.value.connections.items.map((c) => (c.acct_provider === data.provider ? connection : c)) :
            [...$settingsAccounting.value.connections.items, connection],
        },
      });
    }

    return connection;
  } catch (error) {
    $accountingOAuth.reset();
    handleNotification(error);
  } finally {
    $accountingOAuth.loadingEnd();
  }
};

/**
 * @param {ACCT_PROVIDER} provider
 */
export const sync = async (provider) => {
  $settingsAccounting.loadingStart();

  try {
    validateAcctProviderCode(provider);

    if ($syncing.value.isSyncRequested) throw new Error('Please wait for the current sync to start.');
    if ($syncing.value.isSyncing) throw new Error('Please wait for the current sync to finish.');
    $syncing.reset();
    $syncing.update({ isSyncRequested: true });

    const organizationId = $user?.value?.currentOrganization?.id;

    await accruClient.accountingProviders.sync({
      organizationId,
      accountProvider: provider,
      pull: true,
      push: false,
    });
  } catch (error) {
    handleNotification(error);
  } finally {
    $settingsAccounting.loadingEnd();
  }
};

export const updateAccountingConnection = async () => {
  try {
    $accountingConnSettings.loadingStart();

    if (!$accountingConnSettings.value.updateData) return;
    const { connection, updateData: { id, ...updateData } } = $accountingConnSettings.value;
    if (!id || !updateData || !connection || id !== connection.id) throw new Error('No update data!');

    validateAcctProviderCode(connection.acct_provider);
    if (!isValidAcctConnUpdateData()) throw new Error('Invalid update data!');

    const organizationId = $user?.value?.currentOrganization?.id;

    await accruClient.accountingProviders.setPullOptions({
      organizationId,
      accountProvider: connection.acct_provider,
      payload: updateData.pull_configuration,
    });

    if (updateData.automatic_push_enabled) {
      await accruClient.accountingProviders.setPushOptions({
        organizationId,
        accountProvider: connection.acct_provider,
        payload: updateData.push_configuration,
      });
    }

    const updatedConnection = await accruClient.accountingProviders.update({
      organizationId,
      accountProvider: connection.acct_provider,
      data: {
        automatic_pull_enabled: updateData.automatic_pull_enabled,
        automatic_push_enabled: updateData.automatic_push_enabled,
      },
    });

    $accountingConnSettings.update({
      updateData: {
        id: connection.id,
        automatic_pull_enabled: updatedConnection.automatic_pull_enabled,
        automatic_push_enabled: updatedConnection.automatic_push_enabled,
        pull_configuration: updatedConnection.payload?.pull_configuration || {},
        push_configuration: updatedConnection.payload?.push_configuration || {},
      },
      connection: updatedConnection,
    });

    if ($settingsAccounting.value?.connections?.items) {
      $settingsAccounting.update({
        connections: {
          ...$settingsAccounting.value.connections,
          items: $settingsAccounting.value.connections.items.map((c) => (c.id === connection.id ? updatedConnection : c)),
        },
      });
    }

    $accountingOAuth.reset();

    await sync(updatedConnection.acct_provider);

    return updatedConnection;
  } catch (error) {
    handleNotification(error);
  } finally {
    $accountingConnSettings.loadingEnd();
  }
};

export const disconnectAccountingProvider = async () => {
  const { disconnectedProviderCode } = $settingsAccounting.value;
  if (!disconnectedProviderCode || !isAcctProviderCodeValid(disconnectedProviderCode)) return;

  $settingsAccounting.loadingStart();

  try {
    const organizationId = $user?.value?.currentOrganization?.id;

    await accruClient.accountingProviders.disconnect({
      organizationId,
      accountProvider: disconnectedProviderCode,
    });

    $settingsAccounting.update({
      connections: {
        ...$settingsAccounting.value.connections,
        items: $settingsAccounting.value.connections.items.filter((c) => c.acct_provider !== disconnectedProviderCode),
      },
      disconnectedProviderCode: null,
    });
  } catch (error) {
    handleNotification(error);
    fetchAndSetOrganizationAcctProviders();
  } finally {
    $settingsAccounting.loadingEnd();
  }
};

export const toggleSettingsOpen = async (provider) => {
  validateAcctProviderCode(provider);

  $accountingConnSettings.loadingStart();

  if (!$settingsAccounting.value?.connections?.items) return;
  const connection = $settingsAccounting.value.connections.items.find((c) => c.acct_provider === provider);
  if (!connection) return;
  if ($accountingConnSettings.value.updateData?.id) {
    $accountingConnSettings.reset();
    if ($accountingConnSettings.value.connection?.acct_provider !== provider) { return; }
  }

  try {
    const { pullOptions, pushOptions } = await getAccountingSettingOptions(connection.acct_provider);

    $accountingConnSettings.reset();
    $accountingConnSettings.update({
      connection,
      settingOptions: {
        pull_configuration: pullOptions,
        push_configuration: pushOptions,
      },
      updateData: {
        id: connection.id,
        automatic_pull_enabled: connection.automatic_pull_enabled,
        automatic_push_enabled: connection.automatic_push_enabled,
        pull_configuration: connection.payload?.pull_configuration || {},
        push_configuration: connection.payload?.push_configuration || {},
      },
    });
  } catch (error) {
    handleNotification(error);
  } finally {
    $accountingConnSettings.loadingEnd();
  }
};
